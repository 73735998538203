
import React from "react"
import Layout from "../components/layout"
import SEO from '../components/seo';

export default () => (
  <Layout>
    <SEO title="Hawea - New Zealand | Kiwi & Cacahuate" description="A family wedding in Hawea" url="https://kiwicacahuate.com/hawea/" image="https://kiwicacahuate.com/hawea/hawea.jpg" imageWidth="4000" imageHeight="2668" />

    <div className="">
      <div className="story">
        <div className="photoset-section">

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/hawea/hawea.webp, /hawea/hawea.webp 2x" />
                <source srcSet="/hawea/hawea.jpg, /hawea/hawea.jpg 2x" />
                <img src="/hawea/hawea.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09458">
                <source srcSet="/hawea/thumbnail/DSC09458.webp, /hawea/thumbnail/DSC09458-2x.webp 2x" />
                <source srcSet="/hawea/thumbnail/DSC09458.jpg, /hawea/thumbnail/DSC09458-2x.jpg 2x" />
                <img src="/hawea/thumbnail/DSC09458.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09461">
                <source srcSet="/hawea/thumbnail/DSC09461.webp, /hawea/thumbnail/DSC09461-2x.webp 2x" />
                <source srcSet="/hawea/thumbnail/DSC09461.jpg, /hawea/thumbnail/DSC09461-2x.jpg 2x" />
                <img src="/hawea/thumbnail/DSC09461.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09465">
                <source srcSet="/hawea/thumbnail/DSC09465.webp, /hawea/thumbnail/DSC09465-2x.webp 2x" />
                <source srcSet="/hawea/thumbnail/DSC09465.jpg, /hawea/thumbnail/DSC09465-2x.jpg 2x" />
                <img src="/hawea/thumbnail/DSC09465.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09481">
                <source srcSet="/hawea/thumbnail/DSC09481.webp, /hawea/thumbnail/DSC09481-2x.webp 2x" />
                <source srcSet="/hawea/thumbnail/DSC09481.jpg, /hawea/thumbnail/DSC09481-2x.jpg 2x" />
                <img src="/hawea/thumbnail/DSC09481.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09497">
                <source srcSet="/hawea/thumbnail/DSC09497.webp, /hawea/thumbnail/DSC09497-2x.webp 2x" />
                <source srcSet="/hawea/thumbnail/DSC09497.jpg, /hawea/thumbnail/DSC09497-2x.jpg 2x" />
                <img src="/hawea/thumbnail/DSC09497.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>
          <div className="photoset-row">
            <div className="photoset-item ten">
              <picture data-name="DSC09508">
                <source srcSet="/hawea/thumbnail/DSC09508.webp, /hawea/thumbnail/DSC09508-2x.webp 2x" />
                <source srcSet="/hawea/thumbnail/DSC09508.jpg, /hawea/thumbnail/DSC09508-2x.jpg 2x" />
                <img src="/hawea/thumbnail/DSC09508.jpg" alt="Kiwi cachuate images" />
              </picture>
            </div>
          </div>

        </div>
      </div>
    </div>

  </Layout>
)

